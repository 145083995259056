.nav-bar {
    position: fixed;
    display: flex;
    z-index: 9999;
    /* padding: 5px 20px; */
    box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 32%);
    width: -webkit-fill-available;
    transition: 500ms;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    justify-content: flex-end;
}

html:not([data-scroll='0']) .nav-bar {
    transition: 2s;
    background: var(--backgound-fate-black);
}

.nav-bar ul {
    width: -webkit-fill-available;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav-bar ul li {
    display: inline-block;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 15px;
    margin-left: 3rem;
    cursor: pointer;
}

.nav-bar ul li a {
    color: var(--text-white-color);
    text-decoration: none !important;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
}

.active .bar {
    background-color: #101010;
}

.active .nav-item {
    width: 100%;
    padding: 50px;
    margin: 0;
    border-bottom: solid 1px lightgrey;
}

html:not([data-scroll='0']) .bar {
    background-color: var(--background-solid-white);
}

html:not([data-scroll='0']) .active .bar {
    background-color: var(--background-solid-black);
}

@media screen and (max-width: 768px) {
    .nav-menu {
        position: fixed;
        left: -100%;
        top: -20px;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
        padding: 0;
    }

    .nav-bar ul li a {
        color: var(--text-black-color);
        text-decoration: none !important;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 2.5rem;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }
}

.hamburger.active .bar:nth-child(2) {
    opacity: 0;
}

.hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

.nav-lang {
    display: flex;
    flex: auto;
}

.nav-langIcon {
    width: 35px;
    height: 35px;
    padding: 2px;
    border: solid 1px;
    border-radius: 50px;
    background-color: white;
}

.nav-langIcon:hover {
    transition: 300ms;
    box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 32%);
    cursor: pointer;
}