.competence-section-container {
    /* padding: 8rem; */
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    padding-top: 2rem;
    padding-right: 8rem;
    padding-bottom: 2rem;
    padding-left: 8rem;
}

.competence-section {
    /* border: solid black 1px; */
    /* border-radius: 15px; */
    width: 100%;
    /* height: 325px; */
    /* margin-top: 25px; */
    margin-bottom: 50px;
    /* background-color: whitesmoke; */
    /* box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 32%); */

    /* grid-template-columns: 4fr 1fr;
    place-items: center; */
    display: inline-flex;
}

.competence-text {
    /* min-height: 325px; */
    height: fit-content;
    width: 70%;
    background-color: white;

    box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 32%);
    border-radius: 12px;
}

.competence-logo {
    /* height: 100%; */
    width: 30%;
    /* background-color: rgb(71, 42, 165); */

    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 60%;
    /* height: 60%; */
    /* background-color: green; */
}

.logo img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
}

.second-item {
    order: 2;
}

.skill-description {
    margin: 15px;
    padding: 10px;
    color: black;
}

.skill-content {
    min-height: 180px;
    display: grid;
}

.skill-content ul {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    place-items: initial;
    list-style-type: none;
    padding: 0;
}

.two-columns {
    grid-template-columns: 1fr 1fr;
}

.skill-content ul li {
    font-weight: 500;
    padding: 3px;
    margin: auto;
}

@media screen and (max-width: 1024px) {
    .projects-container {
        padding: 0 10px;
    }

    .competence-logo {
        display: none;
    }

    .competence-section-container {
        padding: 20px 30px;
    }

    .competence-text {
        width: 100%;
    }
}
