#experience {
    background-color: #333437;
    height: fit-content;
    padding-bottom: 50px;
}

.experience-section-container {
    /* padding: 8rem; */
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    padding-top: 2rem;
    padding-right: 8rem;
    padding-bottom: 2rem;
    padding-left: 8rem;
    height: 500px;
    overflow-y: scroll;
}

.experience-section:hover {
    cursor: pointer;
}

.heading-experience {
    font-size: 18px;
}

.desc-experience {
    background-color: whitesmoke;
    border-radius: 7px;
    transform: translate(0, 15);
}

.invisible {
    display: none;
}

.experience-title, .experience-subtitle {
    color: #333437;
    margin: 2px;
}

.experience-desc, .experience-desc-list {
    color: #484849;
}

.experience-desc-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.experience-date {
    font-size: 14px;
    color: white;
    background: grey;
    font-weight: 700;
    border-radius: 5px;
    padding: 5px;
    margin-top: 10%;
}

.experience-stack-list {
    margin: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.experience-stack {
    border-radius: 8px;
    background: linear-gradient(#606c88,#3f4c6b);
    color: white;
    padding: 5px;
    margin: 5px;
}

.experience-logo {
    max-width: 150px;
    max-height: 75px;
    margin-top: 10px;
}