html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

:root {
  --text-black-color: black;
  --text-white-color: #F2F2F2;
  --text-black-shadow: rgba(0, 0, 0, 0.65);

  --background-solid-white: #F2F2F2;
  --background-solid-black: rgba(32, 31, 36, 1);

  --backgound-fate-black: rgba(0, 0, 0, 0.8);
}