#title {
    font-size: 3em;
    padding: 1em;
    margin: 0;
}

.title-dark-color {
    color: var(--text-black-color);
}

.title-white-color {
    color: var(--text-white-color);
}