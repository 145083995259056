#about {
    background-color: var(--background-solid-white);
    height: fit-content;
    width: 100%;
}

.about-container {
    padding: 8rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    place-items: center;
}

.about-section {
    margin: 20px;
}

.about-basic-information {
    padding: 20px;
    margin: -20px;
    position: relative;
    top: -5px;
    background-color: var(--background-solid-black);
    border-radius: 7px;
    color: white;
    font-size: 16px;
    font-weight: 800;
}

.about-basic-information p {
    margin: 0;
}

.about-text-section {
    text-align: justify;
    color: var(--text-white-color);
    background-color: var(--background-solid-black);
    border-radius: 9px;
    padding: 40px;
}

.about-text-section h3 {
    margin-top: 0px;
    font-size: 1.5rem;
}

.about-pp {
    height: 24rem;
    align-items: center;
    border-radius: 15px 15px 0px 0px;
    box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 15%);
}

@media screen and (max-width: 1024px) {
    .about-container {
        display: flex;
        flex-direction: column;
        padding: 0px;
        padding-top: 50px;
    }
}