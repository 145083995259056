.contact-box {
    width: 300px;
    height: 200px;
    background-color: white;
    border-radius: 7px;
    box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 15%);
    margin: 20px auto;
}

.contact-icon {
    color: dimgrey;
}

.contact-icon:hover {
    transition: 1s;
    box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 32%);
    margin: 1px;
    border-radius: 7px;
}