
@media (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .home-profil-container {
        /* background-color: orange; */
    }
    .home-name, .home-job-title {
        font-size: 24px;
    }
}

@media screen and (min-width: 479px) and (max-width: 768px) {
    .home-profil-container {
        /* background-color: green; */
    }
    .home-name, .home-job-title {
        font-size: 32px;
    }
    .competence-logo {
        display: none;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .home-profil-container {
        /* background-color: blue; */
    }
    .home-name, .home-job-title {
        font-size: 54px;
    }
}

@media screen and (min-width: 1025px) {
    .home-profil-container {
        /* background-color: red; */
    }
    .home-name, .home-job-title {
        font-size: 64px;
    }
}