#projects {
    background-color: #bbbbbb;
}

.projects-container {
    display: flex;
    flex-flow: row wrap;
    height: 500px;
    overflow-y: scroll;
}

.project-section {
    width: 550px;
    margin: 15px auto;
    background-color: whitesmoke;
    position: relative;
    border-radius: 15px;
    box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 32%);
}

.project-section img {
    width: 450px;
}

.project-img-box {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    border-radius: 16px;
}

.project-overlay:hover {
    height: 100%;
    border-radius: 15px;
}

.project-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    overflow: hidden;
    width: 100%;
    height: 76px;
    transition: .5s ease;
    -webkit-transition: all 0.5s;
    -webkit-border-bottom-right-radius: 15px;
    -webkit-border-bottom-left-radius: 15px;
    -moz-border-radius-bottomright: 15px;
    -moz-border-radius-bottomleft: 15px;
    border-radius: 0px 0px 15px 15px;
}

.project-title {
    margin-top: 25px;
}

.project-content {
    margin-top: 30px;
    box-shadow: 0px -1px 5px 0px rgb(0 0 0 / 32%);
    height: -webkit-fill-available;
    overflow-y: scroll;
    padding: 20px 10px 50px 10px;
}

.project-video-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-bottom: 76px;
}

.project-section iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 15px 15px 0px 0px;
}

.project-stack-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-stack {
    border-radius: 8px;
    /* background: linear-gradient(
        #f2709c,
        #ff9472
    ); */
    background: linear-gradient(
        #43cea2,
        #185a9d
    );
    color: white;
    padding: 5px;
    margin: 5px;
}

@media screen and (max-width: 768px) {
    .projects-container {
        padding: 0 10px;
    }
}