
.home-container {
    height: 100vh;
}

.home-background {
    background-image: url( 'assets/home_wallpaper.png' );
    height: 100%;
    position: relative;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-profil-container {
    display: flex;
    flex-direction: column;
    color: var(--text-white-color);
    padding: 6rem 1rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    text-align: left;
}

hr {
    width: 5rem;
    margin-left: 20px;
    border: 1px solid var(--text-white-color);
}

.home-name {
    margin: 0;
    padding: 1rem;
    color: var(--text-white-color);
    width: max-content;
    left: 1rem;
    text-shadow: 0px 4px 4px var(--text-black-shadow);
}

.home-job-title {
    padding: 1rem;
    color: var(--text-white-color);
    margin: 0;
    width: max-content;
    top: 6rem;
    text-shadow: 0px 4px 4px var(--text-black-shadow);
}

.home-btn {
    margin: 5px;
    padding: 18px 32px;
    border: none;
    border-radius: 300px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    background-color: #3498DB;
    margin-bottom: 0;
    font-size: 14px;
    cursor: pointer;
}

.home-btn:hover {
    background-color: #1e5981;
    transition: 1s;
}